





























import { Component, Mixins, Ref } from 'vue-property-decorator'

import Confirmation from '@/components/modals/Confirmation.vue'
import LinkTabs from '@/components/_uikit/LinkTabs.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import ManagerCoursesModule from '@/store/modules/manager/courses'
import { CourseLargeResource, CourseType, GroupShortResource, GroupType } from '@/store/types'

enum MenuActions {
  ADD_MG_LITE = 'addMGLite',
  ADD_MG_PLUS = 'addMGPlus',
  COPY_COURSE = 'copyCourse',
  DELETE_GROUP = 'deleteGroup',
  DELETE_COURSE = 'deleteCourse',
}

@Component({
  components: {
    Confirmation,
    LinkTabs,
    Parameters,
  },
})
export default class CoursesItem extends Mixins(NotifyMixin, PermissionsMixin) {
  @Ref() confirm!: Confirmation

  private get isMobile() {
    return this.$vuetify.breakpoint.width < 600
  }

  private get courseID () {
    return +this.$route.params.courseID
  }

  private get breadcrumbs () {
    return [{ name: 'Курсы', path: '/manager/control/courses' }]
  }

  private get tabs () {
    return this.course
      ? this.course.groups.map((group: GroupShortResource) => ({
        exact: false,
        name: this.isSpecial && group.type.value === GroupType.DEFAULT ? 'Общая группа' : group.type.name,
        to: {
          name: 'manager.control.courses.item.group',
          params: {
            courseID: this.course?.id,
            groupType: group.type.value,
          },
        },
      }))
      : []
  }

  private get menu () {
    const list = []

    if (this.course) {
      if (this.hasPermission(this.Permission.COURSE_EDIT)) {
        if (this.course.type.value === CourseType.DEFAULT) {
          if (!this.course.groups.find((group: GroupShortResource) => group.type.value === GroupType.PLUS)) {
            list.push({ name: 'Добавить группу Плюс', value: MenuActions.ADD_MG_PLUS })
          }
          if (!this.course.groups.find((group: GroupShortResource) => group.type.value === GroupType.LITE)) {
            list.push({ name: 'Добавить группу Лайт', value: MenuActions.ADD_MG_LITE })
          }
        }

        list.push({ name: 'Копировать курс', value: MenuActions.COPY_COURSE })
      }

      if (this.group && this.group.type.value !== GroupType.DEFAULT && this.hasPermission(this.Permission.COURSE_DELETE)) {
        list.push({ name: 'Удалить группу', value: MenuActions.DELETE_GROUP })
      }

      if (this.hasPermission(this.Permission.COURSE_DELETE)) {
        list.push({ name: 'Удалить весь курс', value: MenuActions.DELETE_COURSE })
      }
    }

    return list
  }

  private get course () {
    return ManagerCoursesModule.course
  }

  private get group () {
    return ManagerCoursesModule.group
  }

  private get isSpecial () {
    return this.course ? this.course.type.value === CourseType.SPECIAL : false
  }

  private created () {
    if (!this.course || this.course.id !== this.courseID) {
      this.fetchCourse()
    } else {
      this.checkRedirect(this.course)
    }
  }

  private checkRedirect (course: CourseLargeResource) {
    if (this.$route.name === 'manager.control.courses.item') {
      this.$router.replace({
        name: 'manager.control.courses.item.group',
        params: {
          courseID: course.id.toString(),
          groupType: course.groups[0].type.value.toString(),
        },
      })
    }
  }

  private fetchCourse () {
    ManagerCoursesModule.fetchCourse(this.courseID)
      .then((response: CourseLargeResource) => {
        this.checkRedirect(response)
      })
      .catch((error: any) => {
        this.notifyError(error)
        this.$router.push({ name: 'manager.control.courses' })
      })
  }

  private handleMenuItemClick (action: MenuActions) {
    const handlers = {
      [MenuActions.ADD_MG_PLUS]: this.handleCreateMGPlus,
      [MenuActions.ADD_MG_LITE]: this.handleCreateMGLite,
      [MenuActions.COPY_COURSE]: this.confirmCopyCourse,
      [MenuActions.DELETE_GROUP]: this.confirmDeleteGroup,
      [MenuActions.DELETE_COURSE]: this.confirmDeleteCourse,
    }

    handlers[action]()
  }

  private handleCreateMGPlus () {
    if (this.course) {
      ManagerCoursesModule.saveGroup({
        courseID: this.course.id,
        type: GroupType.PLUS,
      })
        .then(() => {
          this.$router.push({ params: { groupType: GroupType.PLUS } })
          this.notifySuccess('Группа Плюс добавлена')
          ManagerCoursesModule.fetchCourse((this.course as CourseLargeResource).id)
        })
        .catch(this.notifyError)
    }
  }

  private handleCreateMGLite () {
    if (this.course) {
      ManagerCoursesModule.saveGroup({
        courseID: this.course.id,
        type: GroupType.LITE,
      })
        .then(() => {
          this.notifySuccess('Группа Лайт добавлена')
          ManagerCoursesModule.fetchCourse((this.course as CourseLargeResource).id)
        })
        .catch(this.notifyError)
    }
  }

  private confirmCopyCourse () {
    this.confirm.open(
      'Копирование курса',
      `Вы действительно хотите скопировать курс <span class="text-body-3 secondary--text">${this.course?.title}</span>? Необходимо ввести новое название для копии.`,
      {
        buttonConfirmText: 'Сохранить',
        promptPlaceholder: 'Введите название курса',
        skin: 'secondary',
        withPrompt: true,
      },
    )
      .then(this.handleCopyCourse)
      .catch(() => {return})
  }

  private handleCopyCourse (title: string) {
    if (!title) {
      this.notifyError('Введите название курса!')
      return
    }

    if (this.course) {
      ManagerCoursesModule.copyCourse({
        courseID: this.course.id,
        params: { title },
      })
        .then((response: CourseLargeResource) => {
          this.notifySuccess('Курс успешно скопирован')
          this.$router.push({
            name: 'manager.control.courses.item',
            params: { courseID: response.id.toString() },
          })
        })
        .catch(this.notifyError)
    }
  }

  private confirmDeleteGroup () {
    if (this.course && this.group) {
      this.confirm.open(
        'Удаление группы',
        `Вы действительно хотите удалить группу <span class="text-body-3 secondary--text">${this.group.type.name}</span> из курса <span class="text-body-3 secondary--text">${this.course.title}</span>? После удаления группу невозможно будет  восстановить.`,
        {
          buttonConfirmText: 'Удалить',
        },
      )
        .then(this.handleDeleteGroup)
        .catch(() => {return})
    }
  }

  private handleDeleteGroup () {
    if (this.course && this.group) {
      ManagerCoursesModule.deleteGroup({
        courseID: this.course.id,
        type: this.group.type.value as GroupType,
      })
        .then(() => {
          this.notifySuccess('Группа успешно удалена')
          if (this.course) {
            this.$router.replace({
              name: 'manager.control.courses.item',
              params: {
                courseID: this.course.id.toString(),
                groupType: GroupType.DEFAULT,
              },
            })
          } else {
            this.$router.replace({ name: 'manager.control.courses' })
          }
        })
        .then(this.fetchCourse)
        .catch(this.notifyError)
    }
  }

  private confirmDeleteCourse () {
    this.confirm.open(
      'Удаление курса',
      `Вы действительно хотите полностью удалить курс <span class="text-body-3 secondary--text">${this.course?.title}</span>? После удаления курс невозможно будет восстановить.`,
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(this.handleDeleteCourse)
      .catch(() => {return})
  }

  private handleDeleteCourse () {
    if (this.course) {
      ManagerCoursesModule.deleteCourse(this.course.id)
        .then(() => {
          this.notifySuccess('Курс успешно удален')
          this.$router.replace({ name: 'manager.control.courses' })
        })
        .catch(this.notifyError)
    }
  }

  private confirmPublish () {
    this.confirm.open(
      'Публикация курса',
      `Вы действительно хотите опубликовать курс <span class="text-body-3 secondary--text">${this.course?.title}</span>? После публикации курс станет доступен к покупке на сервисе.`,
      {
        buttonCancelText: 'Отменить',
        buttonConfirmText: 'Опубликовать',
        skin: 'teal',
      },
    )
      .then(() => {
        this.toggleCourse(true)
      })
      .catch(() => {return})
  }

  private confirmUnpublish () {
    this.confirm.open(
      'Снятие публикации курса',
      `Вы действительно хотите снять с публикации курс <span class="text-body-3 secondary--text">${this.course?.title}</span>? После этого курс перестанет быть доступным к покупке на сервисе.`,
      {
        buttonCancelText: 'Отменить',
        buttonConfirmText: 'Снять с публикации',
        skin: 'teal',
      },
    )
      .then(() => {
        this.toggleCourse(false)
      })
      .catch(() => {return})
  }

  private toggleCourse (publish: boolean) {
    if (this.course) {
      ManagerCoursesModule.toggleCourse({
        courseID: this.course.id,
        publish,
      })
        .then(() => {
          this.notifySuccess(publish ? 'Курс опубликован' : 'Курс снят с публикации')
        })
        .catch(this.notifyError)
    }
  }
}
